import React from "react";

// Style
import "./style.scss";

export default function Loader() {
  return (
    <div className="loading-container">
      <svg className="loading" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          fill="none"
          r="20"
          strokeWidth="4"
        />
      </svg>
      <div className="title">Loading...</div>
    </div>
  );
}
