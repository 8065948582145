import * as React from "react";
import Helmet from "react-helmet";

import "../assets/scss/index.scss";

type Props = {
  children: React.ReactNode;
  pageName: string;
};

const Layout = ({ children, pageName }: Props) => {
  let className = "";

  if (pageName) {
    className = `${className} ${pageName}`;
  }

  return (
    <>
      <Helmet bodyAttributes={{ class: className }} />
      <div className="wrapper">
        <main>{children}</main>
      </div>
    </>
  );
};

export default Layout;
